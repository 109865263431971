const getOauthUrl = (clientId: string, redirectUri: string) =>
  // eslint-disable-next-line max-len
  `https://www.keepmail.com/oauth/identify?response_type=code&scope=email&client_id=${clientId}&redirect_uri=${redirectUri}`;

const keepmail = {
  getOauthUrl,
};

export {
  keepmail,
};
